import * as React from "react"
import Layout from '../components/layout'
import '../styles/pricing.scss';
import {StaticImage} from "gatsby-plugin-image"

const Pricing = () => {
    return (
        <Layout>
            <main>
                <div className='plan-container'>
                    <div className='price-title'>Let Start Together With Each Other</div>
                    <div className='pricing-wrapper'>
                        <div className='pricing-container'>
                            <div className='plan-name'>Essential+</div>
                            <div className='plan-text'>All the essentials for starting</div>
                            <div>
                                <span className='plan-price'><sup>$</sup>0</span>
                                <span><sub> /Month</sub></span>
                            </div>
                        </div>
                        <div className='pricing-container'>
                            <div className='plan-name'>Express+</div>
                            <div className='plan-text'>Express features for scaling your business</div>
                            <div>
                                <span className='plan-price'><sup>$</sup>99</span>
                                <span><sub> + tax/Month</sub></span>
                            </div>
                        </div>

                        <div className='pricing-container'>
                            <div className='plan-name'>Professional+</div>
                            <div className='plan-text'>Advanced features for scaling your business</div>
                            <div>
                                <span className='plan-price'><sup>$</sup>199</span>
                                <span><sub> + tax/Month</sub></span>
                            </div>
                        </div>
                    </div>
                    <div className='pricing-container processing-fee'>
                        <div className='plan-name'>Smart Valet Tickets</div>
                            <p>Buy with us or print your own</p>
                        <div className='plan-features'>
                            <p>
                            <ul>
                                <li> 4-part (2.67” x 11”) $65 per 1000 ticket + tax + shipping</li>
                                <li> 4-part (2.75” x 8”) $45 per 1000 ticket + tax + shipping</li>
                                <li> 3-part (2.67” x 5.5”) $29 per 1000 ticket + tax + shipping</li>
                                <li> Contact sale for bulk ticket order discount or custom design.</li>
                            </ul>
                            </p>
                        </div>
                    </div>

                    <div className='pricing-container processing-fee'>
                        <div className='plan-name'>Processing Fee</div>
                        <div className='plan-text'>If the merchant is enrolled in a cash discount program then the<br/> payment processing
                            fee will be passed to the customer.</div>
                        <div className='plan-features'>
                            <li className='plan-fee'> 3.5% + 30¢ per transaction</li><br/>
                            <li className='plan-fee'> $59 + tax + shipping ( terminal / card reader for offline card-present<br/>
                                transaction.)</li>
                        </div>
                    </div>
                </div>
                <div className='note-container'>
                    <div className='note'>To Be Noted:</div>
                    <div className='plan-features'>
                        <li className='plan-fee'> Free trial (up to 500 tickets).</li><br/>
                        <li className='plan-fee'> No hidden charges. No contract.</li><br/>
                        <li className='plan-fee'> No download app requires for end-consumer.</li><br/>
                        <li className='plan-fee'> No app require at the valet end, it can be used with text alerts only.</li><br/>
                        <li className='plan-fee'> GDPR and end-to-end data protection and security compliance.</li><br/>
                        <li className='plan-fee'> Contact sales to get tailored software related to the standard workflow of parking,
                            food, and hospitality industries.</li>
                    </div>
                </div>

                
            </main>
        </Layout>
    )
}
export default Pricing
